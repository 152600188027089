// Example Article.js
import './Article.css'; // Make sure your CSS file is correctly linked
const Article = () => {
    return (
      <div className="article">
        <h2 id="what-is-ip">What is IP?</h2>
        <p>
          An Internet Protocol (IP) address is a crucial element for devices connected to the internet. Here’s what you need to know:
        </p>
        <ul>
          <li>
            <strong>Definition</strong>: An IP address is a unique identifier assigned to each device on a network, allowing them to communicate effectively.
          </li>
          <li>
            <strong>Formats</strong>: 
            <ul>
              <li><strong>IPv4</strong>: This format consists of four sets of numbers (e.g., 192.168.1.1), separated by periods.</li>
              <li><strong>IPv6</strong>: This newer format uses eight groups of hexadecimal numbers, separated by colons (e.g., 2001:0db8:85a3:0000:0000:8a2e:0370:7334).</li>
            </ul>
          </li>
          <li>
            <strong>Purpose</strong>: The primary role of an IP address is to identify and locate devices on a network, ensuring that data is sent to the correct destination.
          </li>
        </ul>
  
        <h2 id="ipv4">IPv4</h2>
        <p>
          IPv4 is one of the most widely used IP protocols. Here are its main characteristics:
        </p>
        <ul>
          <li>
            <strong>Address Length</strong>: IPv4 addresses are 32 bits long, allowing for approximately 4.3 billion unique addresses.
          </li>
          <li>
            <strong>Structure</strong>: Each IPv4 address consists of four octets (e.g., 192.168.0.1), each representing a decimal number between 0 and 255.
          </li>
          <li>
            <strong>Limitations</strong>: With the exponential growth of internet-connected devices, the pool of available IPv4 addresses has been exhausted, prompting the need for IPv6.
          </li>
        </ul>
  
        <h2 id="ipv6">IPv6</h2>
        <p>
          IPv6 was created to address the limitations of IPv4. Key points include:
        </p>
        <ul>
          <li>
            <strong>Address Length</strong>: IPv6 addresses are 128 bits long, enabling a virtually limitless number of unique addresses (around 340 undecillion addresses).
          </li>
          <li>
            <strong>Structure</strong>: An IPv6 address is represented as eight groups of four hexadecimal digits (e.g., 
            <strong>2001:0db8:85a3:0000:0000:8a2e:0370:7334</strong>).
          </li>
          <li>
            <strong>Benefits</strong>: 
            <ul>
              <li>Enhanced security features, including mandatory IPsec support.</li>
              <li>Efficient routing and network autoconfiguration capabilities.</li>
              <li>Better support for mobile devices and the Internet of Things (IoT).</li>
            </ul>
          </li>
        </ul>
  
        <h2 id="about">About</h2>
        <p>
          This application is designed to provide users with information about their public IP address and related details. Here's what you can find:
        </p>
        <ul>
          <li>Your current IP address (both IPv4 and IPv6).</li>
          <li>Your geographic location based on your IP address.</li>
          <li>Your Internet Service Provider (ISP) information.</li>
          <li>Your local time and timezone based on your IP address.</li>
        </ul>
  
        <h2 id="faq">FAQ - What is My IP?</h2>
        <p>
          Here are some frequently asked questions about IP addresses and their functionalities:
        </p>
        <ul>
          <li>
            <strong>What is my IP address?</strong><br />
            Your IP address is a unique identifier assigned to your device by your Internet Service Provider (ISP). You can find it displayed at the top of this page.
          </li>
          <li>
            <strong>What is the difference between IPv4 and IPv6?</strong><br />
            IPv4 is the older format, consisting of 32 bits and providing about 4.3 billion addresses. IPv6, on the other hand, is 128 bits long and allows for an almost infinite number of addresses.
          </li>
          <li>
            <strong>Can I hide my IP address?</strong><br />
            Yes, you can hide your IP address using Virtual Private Networks (VPNs) or proxy servers, which mask your real IP address and provide you with a different one.
          </li>
          <li>
            <strong>Why is my IP address important?</strong><br />
            Your IP address is vital for routing internet traffic to your device. It ensures that data sent over the internet reaches the correct destination.
          </li>
          <li>
            <strong>How can I check my IP address?</strong><br />
            You can check your IP address using various online tools, including this application, or by entering "What is my IP" in a search engine.
          </li>
        </ul>
  
        <h2 id="help">Help</h2>
        <p>
          If you have questions or face issues while using this application, here are some helpful resources:
        </p>
        <ul>
          <li>Check the FAQ section for common questions and answers.</li>
          <li>Contact our support team for personalized assistance.</li>
          <li>Explore our documentation for detailed information about IP addresses and their functions.</li>
        </ul>
      </div>
    );
  };
  
  export default Article;
  