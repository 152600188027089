import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './IPInfo.css';
import ReactCountryFlag from 'react-country-flag';
import Article from './Article';

const IPInfo = () => {
  const [ipData, setIpData] = useState(null);
  const [ipv4, setIpv4] = useState(null);
  const [error, setError] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    const fetchIpData = async () => {
      try {
        const response = await axios.get(`https://ipapi.co/json/`);
        setIpData(response.data);

        if (response.data.ip.includes(':')) {
          const ipv4Response = await axios.get(`https://api.ipify.org/?format=json`);
          setIpv4(ipv4Response.data.ip);
        } else {
          setIpv4(response.data.ip);
        }
      } catch (err) {
        setError("Error fetching IP data: " + err.message);
        console.error(err);
      }
    };

    fetchIpData();
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => setCopySuccess('Copied!'),
      () => setCopySuccess('Failed to copy')
    );

    setTimeout(() => setCopySuccess(''), 2000); // Clear message after 2 seconds
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!ipData) {
    return <div>Loading IP information...</div>;
  }

  const {
    ip,
    city,
    region,
    country_name,
    org,
    timezone,
    country_code,
  } = ipData;

  const ispMainName = org.split(' AS ')[0];

  return (
    <div className="ip-info">
      <header className="header">
        <nav>
          <ul>
            <li><a href="#what-is-ip">What is IP?</a></li>
            <li><a href="#ipv4">IPv4</a></li>
            <li><a href="#ipv6">IPv6</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#faq">FAQ</a></li>
            <li><a href="#help">Help</a></li>
          </ul>
        </nav>
      </header>

      <div className="info-box">
        <h2>My IP Address is:</h2>
        <table className="ip-table">
          <tbody>
            <tr>
              <td><strong>IPv4:</strong></td>
              <td>
                {ipv4 || 'Not Available'}
                {ipv4 && (
                  <span className="copy-icon" onClick={() => copyToClipboard(ipv4)} title="Copy IPv4 address">
                    <i className="fas fa-clone"></i>
                  </span>
                )}
                {copySuccess && <span className="copy-success">{copySuccess}</span>}
              </td>
            </tr>
            <tr>
              <td><strong>IPv6:</strong></td>
              <td>{ip}</td>
            </tr>
          </tbody>
        </table>

        <h3>My IP Information:</h3>
        <table className="info-table">
          <tbody>
            <tr>
              <td><strong>ISP:</strong></td>
              <td>{ispMainName}</td>
            </tr>
            <tr>
              <td><strong>City:</strong></td>
              <td>{city}</td>
            </tr>
            <tr>
              <td><strong>Region:</strong></td>
              <td>{region}</td>
            </tr>
            <tr>
              <td><strong>Country:</strong></td>
              <td>
                {country_name}
                <ReactCountryFlag
                  countryCode={country_code}
                  svg
                  style={{ width: '1.5em', height: '1.5em', marginLeft: '5px' }}
                />
              </td>
            </tr>
            <tr>
              <td><strong>Connection Type:</strong></td>
              <td>{ip.includes(':') ? 'IPv6' : 'IPv4'}</td>
            </tr>
            <tr>
              <td><strong>Local time:</strong></td>
              <td>{new Date().toLocaleTimeString()} - {new Date().toLocaleDateString()}</td>
            </tr>
            <tr>
              <td><strong>Timezone:</strong></td>
              <td>{timezone}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Article />
    </div>
  );
};

export default IPInfo;
